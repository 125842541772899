<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="400px"
    :top="'0'"
    custom-class="email_global_prompt"
    :modal="false"
    :append-to-body="true"
    :destroy-on-close="true"
  >
    <div class="bodyBox" :title="text">
      <img :src="icon" class="iconStyle" />
      <div class="textStyle">
        {{ text }}
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "EmailGlobalPrompt",
  data() {
    return {
      dialogVisible: false,
      timer: null,
    };
  },
  mounted() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.dialogVisible = true;
    this.timer = setTimeout(() => {
      this.$store.commit("setResultDialog", null);
      this.dialogVisible = false;
    }, 3000);
  },
  computed: {
    resultDialog() {
      return this.$store.state.dialog.resultDialog;
    },
    icon() {
      let url = require("../../../../assets/images/email/loginDialog/success_2.png");
      if (this.resultDialog) {
        switch (this.resultDialog.type) {
          case "success":
            url = require("../../../../assets/images/email/loginDialog/success_2.png");
            break;
          case "fail":
            url = require("../../../../assets/images/email/loginDialog/fail.png");
            break;
        }
      }
      return url;
    },
    text() {
      let text = this.$t("successfulRegistrationPrompt");
      if (this.resultDialog) {
        text = this.resultDialog.text;
      }
      return text;
    },
  },
};
</script>
<style lang="stylus" scoped></style>
<style lang="stylus">
.email_global_prompt
  padding: 0 !important;
  height: 80px;
  overflow: hidden;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    .bodyBox
      width: 100%;
      height: 100%;
      padding:0 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      user-select: none;
      .iconStyle
        width: 36px;
        height: 36px;
        flex-shrink: 0;
      .textStyle
        flex: 1;
        min-width: 0;
        color: #292D32;
        font-size: 22px;
        padding-left: 16px;
        overflow: hidden;
        word-break: break-word;
</style>
