var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "400px",
        top: "0",
        "custom-class": "email_global_prompt",
        modal: false,
        "append-to-body": true,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "bodyBox", attrs: { title: _vm.text } }, [
        _c("img", { staticClass: "iconStyle", attrs: { src: _vm.icon } }),
        _c("div", { staticClass: "textStyle" }, [
          _vm._v("\n      " + _vm._s(_vm.text) + "\n    ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }